<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Allowance Requests
            </h1>
          </div>
          <v-row no-gutters>
            <v-col cols="12" class="">
              <v-row no-gutters>
                <v-col cols="6">
                <v-select v-model="action" class="pt-5" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0"   label="Actions" return-object outlined dense/>
              </v-col>
               <v-col cols="6 d-flex">
                 <div class="submit-button-container">
                   <v-btn
                    small
                    class="btn btnSubmit"
                    :disabled="action === ''"
                    @click.prevent="actionSelected(action)"
                    :loading ="loading"
                  >Submit</v-btn>
                   <!-- <Button
                  :label="'Submit'"
                  :btnType="'Submit'"
                  @onClick="changeAction"
                /> -->
                 </div>
               </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="col-md-12 ml-auto mr-auto">
                <v-text-field v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details></v-text-field>
              </div>
          <div v-if="loadingPendingAllowance" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
           <v-data-table v-else
                         :headers='headers'
                         :items="pendingAllowances"
                         :search="search"
                         show-select
                         :single-select="false"
                         v-model="selected"
                         item-key="id">
             <template v-slot:item.employeeName="{ item }">
               <button class="view-button" v-on:click="viewDetail(item)">{{item.employeeName}}</button>
             </template>
             <template v-slot:item.summary="{ item }">
               <button class="view-button" v-on:click="viewDetail(item)">{{item.summary}}</button>
             </template>
             <template v-slot:item.actions="{ item }">
               <div class="d-flex">
                 <!-- <Button :btnType="'Submit'" :label="'View'" @onClick="viewDetail(item)" class="mr-2 btn-success view-button" /> -->
                 <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2" />
                 <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2" />
                 <Button :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" />
               </div>
             </template>
           </v-data-table>
            <!-- <v-row no-gutters class="mt-5">
              <v-col cols="6" class="m-auto">
                <v-textarea
                  label="Comment"
                  no-resize
                  rows="4"
                  dense
                  outlined
                  v-model="comment"
                ></v-textarea>
              </v-col>
            </v-row> -->

          <!-- Reject dialog  -->
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField  v-model="formData.comments" :label="'Comment'"/>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                  :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :isLoading="rejecting"
                  :disabled="!valid"
                  class="mr-4"
                />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Dialog ref="detailView" :title="'Allowance Request Detail'" :width="700">
            <v-row v-if="requestDetail !== null">
              <div class="row text-left">
                <div class="col-md-6">Personnel Name</div>
                <div class="col-md-6"><b>{{requestDetail.employeeName}}</b></div>
                <div class="col-md-6">Request Name</div>
                <div class="col-md-6"><b>{{requestDetail.allowanceName}}</b></div>
                <div class="col-md-6">Approval Status</div>
                <div class="col-md-6"><b>{{requestDetail.approvalStatus}}</b></div>
                <div class="col-md-6">Next Approval</div>
                <div class="col-md-6"><b>{{requestDetail.approvalName}}</b></div>
                <div class="col-md-6">Salary Period</div>
                <div class="col-md-6"><b>{{requestDetail.salaryPeriod}}</b></div>
                <div class="col-md-6">Created By</div>
                <div class="col-md-6"><b>{{requestDetail.createdBy}}</b></div>
                <div class="col-md-6">Date Requested</div>
                <div class="col-md-6"><b>{{requestDetail.requestDate}}</b></div>
                <div class="col-md-6">Comment</div>
                <div class="col-md-6"><b>{{requestDetail.comment}}</b></div>
              </div>
              <v-divider></v-divider>

              <div class="row text-left">

              </div>
              <v-simple-table style="width: 100%; overflow-x: auto">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="item in requestItems" :key="item.id">
                        {{item.date}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="item in requestItems" :key="item.id"><p>{{item.value}}</p></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
            <!-- Logs-->
            <v-row>
              <v-card width="600" elevation="0">
                <v-tabs v-model="tab">
                  <v-tab href="#tab-2">Log</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-2">
                    <v-card class="pt-2" flat>
                      <div v-if="loadingLogs" class="text-center">
                        <v-progress-circular indeterminate
                          color="primary"></v-progress-circular>
                      </div>
                      <v-data-table v-else
                                    :headers='logHeaders'
                                    :items="logs">
                        <template v-slot:item.activityDate="{ item }">
                          <span>{{ item.activityDate | formatDate }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-row>
            <!--End Logs-->
            <template v-slot:footer>
              <!-- <Button :btnType="'Close'" :label="'Cancel'" @onClick="closeallowanceViewModal" /> -->
            </template>
          </Dialog>
          <!-- Bulk Reject dialog  -->
              <v-dialog v-model="bulkRejectDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline text-center"><h3>Bulk Rejection</h3></v-card-title>
                  <v-divider class="mx-4 mb-5"></v-divider>
                  <v-card-text class="mt-5">
                    <v-form ref="commentForm" v-model="valid">
                      <TextField  v-model="formData.comments" :label="'Comment'"/>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button
                      :label="'Reject'"
                      :btnType="'Submit'"
                      @onClick="changeAction"
                      :isLoading="loading"
                      :disabled="!valid"
                      class="mr-4"
                    />
                    <v-btn color="green darken-1" text @click="bulkRejectDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog >
        </v-container>
      </div>
    </div>
     <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment"  @close="personnelSearch = false" :mType="'forward'" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="changeAction"/>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loading" :dialog="confdialog" :btnTitle="'Yes'" @close="confdialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import {
  APPROVE_REQUEST
} from '@/store/action-type'
import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import moment from 'moment'
// import SelectField from '@/components/ui/form/SelectField.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { salaryPeriodService, requestService, messagingService } from '@/services'

export default {
  components: {
    Button,
    Dialog,
    TextField,
    ConfirmationDialog,
    PersonnelSearch
    // SelectField
  },
  data () {
    return {
      loadingPendingAllowance: false,
      rejectDialog: false,
      bulkRejectDialog: false,
      alertMessage: '',
      forwarding: false,
      alert: false,
      search: '',
      dialog: false,
      confdialog: null,
      valid: false,
      loading: false,
      loadingLogs: false,
      personnelSearch: false,
      alertType: '',
      item: null,
      selected: [],     
      DelegateName: null,
      DelegateEmail: null,
      selectAll: '',
      startDate: '2020-09-19',
      endDate: '2020-10-18',
      salaryPeriod: '',
      rejecting: false,
      action: '',
      comment: '',
      salaryPeriods: [],
      forwardData: [],
      approver: '',
      formData: {
        comments: '',
        id: ''
      },
      headers: [
        {
          text: 'Name',
          value: 'employeeName'
        },
        {
          text: 'Month',
          value: 'month'
        },
        {
          text: 'Year',
          value: 'year'
        },
        {
          text: 'Summary',
          value: 'summary'
        },
        {
          text: 'Allowance ',
          value: 'allowance'
        },
        {
          text: 'Approval Status ',
          value: 'approvalStatus'
        },
        {
          text: 'Created By',
          value: 'createdBy'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      logs: [],
      tab: null,
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comments',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }

      ],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      pendingAllowances: [],
      pendingAllowancesComplete: [],
      requestDetail: {},
      requestItems: []
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    approve () {
      this.loading = true
      this.item.status = 1
      this.item.type = 'Allowance'
      this.item.id = this.item.id

      this.$store
        .dispatch(APPROVE_REQUEST, this.item)
        .then((result) => {
          this.showAlertMessage('Approve Request successful', 'success')
          const newArray = this.pendingAllowances.filter(i => i.id !== this.item.id)
          this.pendingAllowances = newArray
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Approve Request failed', 'error')
        }).finally(() => {
          this.loading = false
          this.confdialog = false
        })
    },
    searchFunc (e) {
      let result = this.pendingAllowancesComplete.filter(o => o.employeeName.toLowerCase().includes(e.toLowerCase()))
      this.pendingAllowances = result
      this.addDateHeader()
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    actionSelected (id) {
      if (id.id === 2) {
        this.bulkRejectDialog = true
      }
      if (id.id === 1) { this.dialog = true }
    },
    viewDetail (item) {
      this.requestDetail = {}
      this.requestItems = []

      const detail = {
        allowanceName: item.allowance,
        approvalStatus: item.approvalStatus,
        approvalName: `${item.approver}`,
        employeeName: item.employeeName,
        salaryPeriod: item.salaryPeriodDescription,
        createdBy: item.createdBy,
        requestDate: moment(item.requestDate).format('Do, MMM yyyy')
      }

      this.requestDetail = detail
      requestService.getAllowanceDetailByBatchId(item.batchId).then(response => {
        const { data } = response
        data.forEach(item => {
          let itemValue = ''
          const requestItemDate = item.startDate
          const workHour = item.employeeRequestAllowanceItems.length > 0 ? item.employeeRequestAllowanceItems[0].workHour : null
          if (workHour !== null) {
            itemValue = workHour > 0 ? workHour : 'Yes'
          }
          this.requestItems.push({
            id: item.id,
            date: moment(requestItemDate).format('MMM Do'),
            value: itemValue
          })
        })
      })
      this.$refs.detailView.openModal()

      this.loadingLogs = true
      requestService.allowanceApprovalLogs(item.requestId).then(({ data }) => {
        this.logs = data.logs === null ? [] : data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    openConfiModal (item) {
      this.item = item
      this.confdialog = true
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name     
    },
    getComment (comment) {
      this.comment = comment
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        'EmployeeId': this.item.employeeId,
        'SalaryPeriodId': this.item.salaryPeriodId,
        'SalaryComponentId': this.item.salaryComponentId,        
        'DelegateName': this.DelegateName,
        'delegateemail': this.DelegateEmail,
        'comment': this.comment
      }
      requestService.forwardRequest(data).then((result) => {
        this.showAlertMessage('Request forwarded successfully', 'success')
        const newArray = this.pendingAllowances.filter(i => i.id !== this.item.id)
        this.pendingAllowances = newArray
      }).catch(() => {
        this.showAlertMessage('Failed to forward request', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false       
        this.DelegateName = null
        this.DelegateEmail = null
      })
    },
    filterVal (array, date) {
      let val = array.find(req => req.requestDate === date.value)
      return val ? val.value : ''
    },
    reject () {
      this.rejecting = true
      this.item.status = 2
      this.item.type = 'Allowance'
      this.item.id = this.item.requestId
      this.item.comments = this.formData.comments
      this.$store
        .dispatch(APPROVE_REQUEST, this.item)
        .then((result) => {
          this.showAlertMessage('Rejection successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Rejection failed', 'error')
        }).finally(() => {
          this.rejecting = false
          this.formData.comments = ''
          this.rejectDialog = false
        })
    },
    changeSalaryPeriod () {
      this.loadPendAllowances(this.salaryPeriod.id)
    },
    loadPendAllowances (id) {
      this.loadingPendingAllowance = true
      requestService.getPendingAllowances(id).then(result => {
        this.pendingAllowances = result.data
        this.pendingAllowancesComplete = result.data
      }).catch(() => {
        this.showAlertMessage('There are no items pending your approval', 'error')
      }).finally(() => {
        this.loadingPendingAllowance = false
      })
    },

    sendMail () {
      messagingService.sendMessage().then((result) => {
        console.log(result)
      })
    },
    // addDateHeader () {
    //   this.headers = this.dateArray(this.salaryPeriod.startDate, this.salaryPeriod.endDate, this.pendingAllowances)
    // },
    loadSalaryPeriod () {
      salaryPeriodService.getAllSalaryPeriod().then(result => {
        this.salaryPeriods = result.data
      }).catch(error => {
        console.log(error)
      })
    },
    selectAllItems () {
      if (this.selectAll) {
        let data = []
        this.pendingAllowances.forEach((pa) => data.push({ 'id': pa.id, 'EmployeeId': pa.employeeId, 'SalaryComponentId': pa.salaryComponentId, 'SalaryPeriodId': pa.salaryPeriodId }))
        this.selected = data
      } else {
        this.selected = []
      }
    },
    approverSelected (value) {
      let select = this.selected.find((select) => select.id === value.id)
      let index = null
      for (let i = 0; i < select.length; i++) {
        if (select[i].id === value.id) {
          index = i
          break
        }
      }
      this.selected.splice(index, 1)
      this.selected.push({
        EmployeeId: select.EmployeeId,
        SalaryComponentId: select.SalaryComponentId,
        SalaryPeriodId: select.SalaryPeriodId,
        id: select.id,
        level: value.level,
        comments: select.comments
      })
    },
    changeAction () {
      let status = this.action.id
      if (status === 2) {
        this.comment = this.formData.comments
      }
      this.loading = true

      const data = []
      this.selected.forEach(select => {
        data.push({ EmployeeId: select.employeeId, SalaryPeriodId: select.salaryPeriodId, SalaryComponentId: select.salaryComponentId, comments: this.comment, status: status, level: 0 })
      })

      console.log(data)
      requestService.approveAllowance(data).then(result => {
        this.showAlertMessage(`Allowance Action successful`, 'success')
        const newArray = this.pendingAllowances.filter(i => !this.selected.some(j => j.id === i.id))
        console.log(newArray)
        this.pendingAllowances = newArray
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Allowance Action Failed', 'error')
      }).finally(() => {
        this.action = []
        this.comment = ''
        this.formData.comments = ''
        this.selected = [ ]
        this.loading = false
        this.dialog = false
        this.bulkRejectDialog = false

        // this.$router.go()
      })
    }
  },
  mounted () {
    this.loadSalaryPeriod()
    this.loadPendAllowances(0)
  }
}
</script>
<style scoped>
  .row b {
    word-wrap: break-word;
    word-break: break-all;
  }
.view-button {
  text-align: left;
  border: none;
  color: #0066b2;
}
.view-button:focus {
  border: none;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.actionBar {
  display: flex;
    align-items: center;
    height: 100%;
}
.actionBarContainer{
  width: 100%;
}
.submit-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.btn{
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 14px;
    padding: 18.5px 20px  !important;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 3;
    margin-left: 4px;
}
.btnSubmit{
  background: #0066b2 !important;
}

</style>
